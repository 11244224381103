import { ReactElement } from 'react'

interface Props {
  className?: string
}

const BackgroundBlur = ({ className }: Props): ReactElement => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='912'
      height='760'
      viewBox='0 0 912 760'
      fill='none'
    >
      <g filter='url(#filter0_f_2848_55491)'>
        <path
          d='M726.371 590.318C715.851 599.097 703.449 605.637 689.875 609.518C600.447 635.08 364.391 650.956 228.421 477.558C80.5786 289.02 115.165 119.294 275.393 12.1051C428.529 -90.3394 658.979 71.2868 708.672 143.093C712.894 149.194 716.744 155.314 720.97 161.413C778.576 244.538 857.951 480.506 726.371 590.318Z'
          fill='#41D497'
          fillOpacity='0.1'
        />
      </g>
      <defs>
        <filter
          id='filter0_f_2848_55491'
          x='0.373047'
          y='-154.98'
          width='931.53'
          height='914.188'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='67'
            result='effect1_foregroundBlur_2848_55491'
          />
        </filter>
      </defs>
    </svg>
  )
}

export default BackgroundBlur
