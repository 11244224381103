import { ReactElement, useMemo } from 'react'

import { Button } from 'antd'
import { FormInstance } from 'antd/lib'

import { useTypedDispatch } from '@/hooks/redux'
import { changeStep, setDirection } from '@/redux/features/formStateSlice'

interface IProps {
  form: FormInstance
}

const PartiallyApprovedView = ({ form }: IProps): ReactElement => {
  const dispatch = useTypedDispatch()

  const { title, subtitle } = useMemo(() => {
    switch (true) {
      case form.getFieldValue('hasGuarantors'):
        return {
          title: 'Just a Few Steps Away',
          subtitle:
            'Complete these final questions to see your personalized funding options.'
        }
      default:
        return {
          title: 'You’re almost there!',
          subtitle:
            'Answer the final questions to unlock your personalized loan options.'
        }
    }
  }, [form])

  return (
    <div className={'full-screen-block form-step'}>
      <h2 className={'owner-info__title'}>{title}</h2>
      <p className={'owner-info__subtitle partially-approved'}>{subtitle}</p>
      <Button
        type={'primary'}
        onClick={() => {
          dispatch(setDirection('forward'))

          setTimeout(() => {
            dispatch(changeStep({ direction: 'forward' }))
          }, 100)
        }}
        className={'owner-info__next'}
      >
        Next
      </Button>
    </div>
  )
}

export default PartiallyApprovedView
