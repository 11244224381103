import React, { useEffect } from 'react'

import { Button, DatePicker, Form, Input, Radio, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import dayjs from 'dayjs'

import { patchApplication } from '@/api/app.service'
import DEFAULT_DATE_FORMAT, {
  DEFAULT_DATE_FORMAT_PLACEHOLDER
} from '@/constants/defaultDateFormat'
import { TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import {
  INDUSTRY_OPTIONS,
  USA_STATE_OPTIONS
} from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import calendarIcon from '@/images/icons/calendar-icon.svg'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './BusinessInformation.scss'

const { Option } = Select
const {
  name,
  industryType,
  usaStateType,
  businessStartedAt,
  isStarted,
  isFranchise,
  hasGuarantors
} = TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP
const BusinessInformation: React.FC<IStepProps> = ({ form, next }) => {
  const startedAtValue = useWatch(businessStartedAt, form)
  const isStartedValue = useWatch(isStarted, form)
  const isFranchiseValue = useWatch(isFranchise, form)

  useSetPrefilledData(
    form,
    [
      name,
      industryType,
      usaStateType,
      businessStartedAt,
      isStarted,
      isFranchise,
      hasGuarantors
    ],
    'targetBusinessOverview'
  )

  useEffect(() => {
    if (startedAtValue) form.setFieldsValue({ isStarted: true })
    if (isStartedValue) {
      form.setFieldsValue({ isFranchise: undefined, hasGuarantors: undefined })
    }
    if (isFranchiseValue) {
      form.setFieldValue(hasGuarantors, undefined)
    }
  }, [form, isFranchiseValue, isStartedValue, startedAtValue])

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async (targetBusinessOverview) => {
      await tryCatchWithMessage(async () => {
        const formattedValue = {
          ...targetBusinessOverview,
          usaStateType: USA_STATE_OPTIONS.find(
            (state) => state.label === targetBusinessOverview.usaStateType
          )?.value
        }
        await patchApplication(applicationId, [
          {
            path: '/targetBusinessOverview',
            value: formattedValue
          }
        ])
      })
    }
  )

  return (
    <div className={'business-information form-step'}>
      <h2 className={'business-information__title'}>
        Business Industry & Details
      </h2>
      <p className={'business-information__subtitle'}>
        Please provide a few details about your business:
      </p>
      <div className={'business-information__fields'}>
        <div className={'business-information__field-business-name'}>
          <Form.Item
            name={name}
            className={'business-information__input'}
            label={'Business name'}
            rules={[{ required: true }]}
          >
            <Input placeholder='Enter business name' type='text' />
          </Form.Item>
        </div>
        <div className={'business-information__field-industry'}>
          <Form.Item
            name={industryType}
            className={'business-information__select'}
            label={'Industry'}
            rules={[{ required: true }]}
          >
            <Select placeholder='Select from the list'>
              {INDUSTRY_OPTIONS.map(({ label, value }) => {
                return (
                  <Option value={value} key={value}>
                    {label}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </div>
        <div className={'business-information__field-state'}>
          <Form.Item
            name={usaStateType}
            className={'business-information__select'}
            label={'State'}
            rules={[{ required: true }]}
          >
            <Select
              placeholder='Select from the list'
              showSearch
              popupClassName={'select-dropdown'}
            >
              {USA_STATE_OPTIONS.map(({ label, value }, index) => {
                return (
                  <Option value={label} key={value}>
                    {label}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </div>
        {isStartedValue && (
          <div className={'business-information__field-date'}>
            <Form.Item
              name={businessStartedAt}
              className={'business-information__date-wrapper'}
              label={'Date Business Started'}
            >
              <DatePicker
                placeholder={DEFAULT_DATE_FORMAT_PLACEHOLDER}
                autoComplete='off'
                format={DEFAULT_DATE_FORMAT}
                suffixIcon={<img src={calendarIcon} alt={'calendar'} />}
                className={'business-information__date'}
                disabledDate={(currentDate) => {
                  return currentDate && currentDate > dayjs().endOf('day')
                }}
              />
            </Form.Item>
          </div>
        )}
        <div className={'business-information__field-is-started'}>
          <p className={'business-information__radio-label'}>
            Have you already started the business?
          </p>
          <Form.Item
            name={isStarted}
            rules={[
              {
                required: true,
                message: 'This field is required'
              }
            ]}
            className={'business-information__radio-group'}
          >
            <Radio.Group disabled={!!startedAtValue}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        {isStartedValue === false && (
          <div className={'business-information__field-franchise'}>
            <p className={'business-information__radio-label'}>
              Is it a franchise?
            </p>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'This field is required'
                }
              ]}
              name={isFranchise}
              className={'business-information__radio-group'}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        )}
        {isFranchiseValue === false && (
          <div className={'business-information__field-pledge'}>
            <p className={'business-information__radio-label'}>
              Do any guarantors of the loan have real estate they can pledge as collateral?
            </p>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'This field is required'
                }
              ]}
              name={hasGuarantors}
              className={'business-information__radio-group'}
            >
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        )}
        <Button
          type={'primary'}
          loading={isLoading}
          onClick={() => {
            patch()
          }}
          className={'business-information__next'}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default BusinessInformation
