import React from 'react'

import { Button, Form, Input, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'

import { patchApplication } from '@/api/app.service'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { FUNDING_PURPOSE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { EFundingPurposeType } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './FundsFor.scss'

const { Option } = Select
const { fundingPurposeType, fundingPurpose } = FORM_FIELD_NAMES_MAP

const FundsFor: React.FC<IStepProps> = ({ form, next }) => {
  const fundPurpose = useWatch(fundingPurposeType, form)
  const fundingOtherPurpose = useWatch(fundingPurpose, form)
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({
      fundingPurpose: fundingPurposeValue,
      fundingPurposeType: fundingPurposeTypeValue
    }: {
      fundingPurpose: string
      fundingPurposeType: string
    }) => {
      const values = [
        {
          path: '/fundingPurposeType',
          value: fundingPurposeTypeValue
        }
      ]

      if (fundingPurposeValue) {
        values.push({
          path: '/fundingPurpose',
          value: fundingPurposeValue
        })
      }

      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, values)
      })
    }
  )

  return (
    <div className={'funds-for form-step'}>
      <h2 className={'funds-for__title'}>What is the purpose of the loan?</h2>
      <p className={'funds-for__subtitle'}>
        Please select how you intend to use the loan funds.
      </p>
      <Form.Item
        name={fundingPurposeType}
        rules={[{ required: true, message: 'Please select an option' }]}
        className={'funds-for__select'}
      >
        <Select listHeight={420} placeholder='Select from the list'>
          {FUNDING_PURPOSE_OPTIONS.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {fundPurpose === EFundingPurposeType.Other && (
        <Form.Item
          name={fundingPurpose}
          className={'funds-for__input'}
          rules={[
            {
              required: true,
              message: 'Please enter a reason',
              whitespace: true
            }
          ]}
        >
          <Input placeholder='Please specify a reason' type='text' />
        </Form.Item>
      )}

      <Button
        type={'primary'}
        loading={isLoading}
        onClick={() => {
          patch()
        }}
        className={'funds-for__next'}
        disabled={
          !fundPurpose ||
          (fundPurpose === EFundingPurposeType.Other && !fundingOtherPurpose)
        }
      >
        Next
      </Button>
    </div>
  )
}

export default FundsFor
