import { Radio, Form } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { FC } from 'react';

interface IProps {
    approveContact: () => void;
    declineContact: () => void;
}

const RejectedViewInfo: FC<IProps> = ({ approveContact, declineContact }) => {
    const handleContact = (e: RadioChangeEvent) => {
        if (e.target?.value) {
            approveContact()
        } else {
            declineContact();
        }
    }

  return (
    <div className={'rejection-info'}>
      <h2 className={'rejection-info__title'}>
        Alternative Funding Options Available
      </h2>
      <p className={'rejection-info__subtitle'}>
        While we don't have a direct match for your needs at this time, we can
        connect you with other financing solutions that might work better for
        your business.
      </p>
      <p className={'rejection-info__contact-label'}>
      Would you to like us to give you a call to discuss alternative options?
      </p>
      <Form.Item
            className={'rejection-info__radio-group'}
          >
            <Radio.Group onChange={handleContact}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
    </div>
  )
}

export default RejectedViewInfo;
