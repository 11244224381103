import { ReactElement } from 'react'

import { Flex, Typography } from 'antd'

import StarCheckIcon from '@/components/icons/StarCheckIcon'

const CongratulationsBlock = (): ReactElement => {
  return (
    <div className='congratulations-block'>
      <Flex
        align='center'
        justify='center'
        gap={8}
        className='congratulations-block__title-block'
      >
        <StarCheckIcon />
        <Typography.Title level={4} className='congratulations-block__title'>
          Great News!
        </Typography.Title>
      </Flex>
      <Typography.Text className='congratulations-block__subtitle'>
        Based on the information you've shared, you're likely eligible for one or more of our funding options
      </Typography.Text>
      <Typography.Text className='congratulations-block__subtitle'>
      Let’s finish gathering a few details about the business owners to complete your application.
      </Typography.Text>
    </div>
  )
}

export default CongratulationsBlock
