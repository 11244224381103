import { ReactElement, useState } from 'react'

import { TRejectionState } from '@/types/rejectionState'

import RejectedViewContactApproved from './RejectedViewContactApproved'
import RejectedViewContactDeclined from './RejectedViewContactDeclined'
import RejectedViewInfo from './RejectedViewInfo'

import './RejectedView.scss'

const RejectedView = (): ReactElement => {
  const [state, setState] = useState<TRejectionState>('info')

  const changeState = (newState: TRejectionState) => () => {
    setState(newState)
  }

  const stateFactory = () => {
    switch (state) {
      case 'info':
        return (
          <RejectedViewInfo
            approveContact={changeState('contact_approved')}
            declineContact={changeState('contact_declined')}
          />
        )
      case 'contact_approved':
        return <RejectedViewContactApproved />
      case 'contact_declined':
        return <RejectedViewContactDeclined />
      default:
        return null
    }
  }

  return (
    <div className={'full-screen-block rejection-view'}>{stateFactory()}</div>
  )
}

export default RejectedView
