import { ReactElement, useEffect } from 'react'

import { Button, Form, Radio, Space } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { patchApplication } from '@/api/app.service'
import { uploadFiles } from '@/api/file.service'
import FormUploadInput from '@/components/form/form-upload-input/FormUploadInput'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import {
  addHiddenStep,
  removeHiddenStep,
  setIsAtLeastOneDocumentUploaded
} from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './DriversLicense.scss'

const { driverLicences, driverLicensesAccess } = FORM_FIELD_NAMES_MAP

const DriversLicense = ({ form, next }: IStepProps): ReactElement => {
  const driverLicensesFiles = useWatch(driverLicences, form)
  const [isAgreedPrefill, isAtLeastOneDocumentUploaded] = useTypedSelector(
    ({ formState }) => [
      formState.isAgreedPrefill,
      formState.isAtLeastOneDocumentUploaded
    ]
  )
  const access = useWatch(driverLicensesAccess, form)
  const dispatch = useTypedDispatch()

  useEffect(() => {
    if (access !== undefined && !access) form.setFieldValue(driverLicences, undefined)
  }, [access, form])

  // const a = useTypedSelector((state) => state.formState.hiddenSteps)
  // console.log(a)

  useEffect(() => {
    if (isAgreedPrefill && isAtLeastOneDocumentUploaded) {
      dispatch(removeHiddenStep(8))
    }
  }, [])

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ driverLicences = [] }) => {
      if (!driverLicences.length) {
        if (!isAtLeastOneDocumentUploaded) {
          dispatch(addHiddenStep(8))
        }
        return
      }

      await tryCatchWithMessage(async () => {
        const uploadedDriverLicenses = (await uploadFiles(driverLicences)) || []

        await patchApplication(applicationId, [
          {
            path: '/driverLicences',
            value: uploadedDriverLicenses
          }
        ])
        dispatch(setIsAtLeastOneDocumentUploaded(true))
        dispatch(removeHiddenStep(8))
      })
    }
  )

  return (
    <div className={'drivers-license form-step'}>
      <h2 className={'drivers-license__title'}>
        Do you have access to a government-issued identification?
      </h2>
      <p className={'drivers-license__subtitle'}>
        Please upload a driver’s license, passport, or other government-issued ID.
      </p>

      <Space direction='vertical' size='large'>
        <div>
          <Form.Item
            name={driverLicensesAccess}
            rules={[
              {
                required: true,
                message: 'This field is required'
              }
            ]}
            className={'business-information__radio-group'}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        {access && (
          <div>
            <h3 className={'drivers-license__form-label'}>
              Upload your ID, both sides if applicable
            </h3>
            <FormUploadInput
              name={driverLicences}
              fileList={driverLicensesFiles}
            />
          </div>
        )}
      </Space>
      <Button
        type={'primary'}
        onClick={() => {
          patch()
        }}
        loading={isLoading}
        disabled={access === undefined}
        className={'drivers-license__next'}
      >
        Next
      </Button>
    </div>
  )
}

export default DriversLicense
