import { ReactElement, useEffect } from 'react'

import { Button, Form, Radio, Space } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { patchApplication } from '@/api/app.service'
import { uploadFiles } from '@/api/file.service'
import FormUploadInput from '@/components/form/form-upload-input/FormUploadInput'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import {
  removeHiddenStep,
  setIsAtLeastOneDocumentUploaded
} from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './PersonalTax.scss'

const { personalTaxReturns, personalTaxReturnsAccess } = FORM_FIELD_NAMES_MAP

const PersonalTax = ({ form, next }: IStepProps): ReactElement => {
  const dispatch = useTypedDispatch()
  const ownerPersonalTaxFiles = useWatch(personalTaxReturns, form)

  const ownerAccess = useWatch(personalTaxReturnsAccess, form)

  useEffect(() => {
    if ((ownerAccess !== undefined && !ownerAccess)) form.setFieldValue(personalTaxReturns, undefined)
  }, [ownerAccess, form])

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ personalTaxReturns = [] }) => {
      if (personalTaxReturns.length > 0) {
        await tryCatchWithMessage(async () => {
          const uploadedPersonalTaxReturns =
            (await uploadFiles(personalTaxReturns)) || []

          await patchApplication(applicationId, [
            {
              path: '/personalTaxReturns',
              value: uploadedPersonalTaxReturns
            }
          ])
          dispatch(setIsAtLeastOneDocumentUploaded(true))
          dispatch(removeHiddenStep(8))
        })
      }
    }
  )

  return (
    <div className={'personal-tax form-step'}>
      <h2 className={'personal-tax__title'}>
        Do you have access to your personal tax returns?
      </h2>
      <p className={'personal-tax__subtitle'}>
        Providing your personal tax returns now can help speed up the loan approval process.
      </p>

      <Space direction='vertical' size='large'>
        <div>
          <Form.Item
            name={personalTaxReturnsAccess}
            rules={[
              {
                required: true,
                message: 'This field is required'
              }
            ]}
            className={'personal-information__radio-group'}
          >
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        {ownerAccess && (
          <div>
            <h3 className={'personal-tax__form-label'}>
              Upload your 2022 & 2023 personal tax returns
            </h3>
            <FormUploadInput
              name={personalTaxReturns}
              fileList={ownerPersonalTaxFiles}
            />
          </div>
        )}
      </Space>
      <Button
        type={'primary'}
        onClick={() => {
          patch()
        }}
        loading={isLoading}
        disabled={ownerAccess === undefined}
        className={'personal-tax__next'}
      >
        Next
      </Button>
    </div>
  )
}

export default PersonalTax
