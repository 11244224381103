import { ChangeEventHandler, ReactElement, useEffect, useState } from 'react'

import { Form, Input } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

import {
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'

type TSSNFormItemProps = {
  name: number
  isPrimaryOwner: boolean
}

const { socialSecurityNumber } = OWNER_INFO_FIELD_NAMES_MAP
const { owners } = FORM_FIELD_NAMES_MAP

const SSNFormItem = ({
  name,
  isPrimaryOwner
}: TSSNFormItemProps): ReactElement => {
  const form = useFormInstance()
  const realValue =
    form.getFieldValue([owners, name, socialSecurityNumber]) ?? ''
  const [displayValue, setDisplayValue] = useState(
    '*'.repeat(realValue?.length)
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayValue(displayMaskedValue(realValue, false))
    }, 500)

    return () => clearTimeout(timeout)
  }, [realValue])

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = async ({
    target: { value }
  }) => {
    let newValue
    let shouldShowLastDigit

    if (value.length >= realValue.length) {
      newValue = realValue + value.slice(realValue.length)
      shouldShowLastDigit = !isNaN(Number(value.slice(realValue.length)))
    } else {
      newValue = !value ? '' : realValue.slice(0, -1)
      shouldShowLastDigit = false
    }

    form.setFieldValue(
      [owners, name, socialSecurityNumber],
      formatValue(newValue)
    )
    setDisplayValue(displayMaskedValue(newValue, shouldShowLastDigit))
  }

  const formatValue = (value: string, partFormat = (part: string) => part) => {
    if (!value) return ''

    const sanitized = value.replace(/[^\d-]/g, '')
    const digits = sanitized.replace(/-/g, '')

    const formatted = digits.replace(
      /^(\d{0,3})(\d{0,2})(\d{0,4})$/,
      (_match, p1, p2, p3) => {
        let res = partFormat(p1)

        if (p2) res += `-${partFormat(p2)}`
        if (p3) res += `-${partFormat(p3)}`

        return res
      }
    )

    // check if there is last char - "-" and if it is a suitable place for it
    if (
      sanitized.endsWith('-') &&
      [3, 5].some((length) => digits.length === length)
    ) {
      return formatted + '-'
    }

    return formatted
  }

  const displayMaskedValue = (value: string, showLastDigit: boolean) => {
    if (!value) return ''

    const masked = formatValue(value, (part) => '*'.repeat(part.length))

    return showLastDigit
      ? masked.slice(0, masked.length - 1) + value.slice(-1)
      : masked
  }

  return (
    <Form.Item
      getValueProps={() => ({ value: displayValue })}
      name={[name, socialSecurityNumber]}
      label='Social Security Number'
      className={'ownership__input'}
      style={{ width: '100%' }}
      rules={[
        {
          required: isPrimaryOwner,
          message: 'Please enter a Social Security Number'
        },
        {
          validator: () => {
            const isValid =
              !isPrimaryOwner || /^\d{3}-\d{2}-\d{4}$/.test(realValue)

            return isValid
              ? Promise.resolve()
              : Promise.reject(
                  new Error('Please enter a valid Social Security Number')
                )
          }
        }
      ]}
    >
      <Input
        placeholder='Enter Social Security Number'
        onChange={handleInputChange}
        maxLength={11}
      />
    </Form.Item>
  )
}

export default SSNFormItem
