import { ReactElement } from 'react'

import { Button, Descriptions, DescriptionsProps, Divider } from 'antd'
import dayjs from 'dayjs'

import DEFAULT_DATE_FORMAT from '@/constants/defaultDateFormat'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { BUSINESS_INFO_STEPS } from '@/constants/formSteps'
import { useTypedDispatch } from '@/hooks/redux'
import editIcon from '@/images/icons/edit-icon.svg'
import {
  changeStep,
  setDirection,
  toggleIsNavigatedFromReview
} from '@/redux/features/formStateSlice'
import { TFormOwnerField } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import getFormattedAddress from '@/utils/getFormattedAddress'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'

const { owners: ownersFieldName } = FORM_FIELD_NAMES_MAP

const BusinessOwnersDescriptions = ({
  form
}: Pick<IStepProps, 'form'>): ReactElement => {
  const dispatch = useTypedDispatch()

  const owners: TFormOwnerField[] = form.getFieldValue(ownersFieldName) || []
  const items: DescriptionsProps['items'] = []

  owners.forEach(
    (
      {
        firstName,
        lastName,
        businessWeight,
        email,
        phoneNumber,
        birthDate,
        address
      },
      idx
    ) => {
      const ownerNumber = idx + 1

      items.push(
        {
          key: `${idx}-0`,
          label: `Business Owner ${owners.length > 1 ? ownerNumber : ''}`,
          children: undefined,
          className: 'owner-title'
        },
        {
          key: `${idx}-1`,
          label: 'First Name',
          children: firstName
        },
        {
          key: `${idx}-2`,
          label: 'Last Name',
          children: lastName
        },
        {
          key: `${idx}-3`,
          label: 'Ownership Percentage',
          children: `${businessWeight}%`
        },
        {
          key: `${idx}-4`,
          label: 'Email',
          children: email
        },
        {
          key: `${idx}-5`,
          label: 'Phone Number',
          children: getFormattedPhoneNumber(phoneNumber)
        },
        {
          key: `${idx}-6`,
          label: 'Date of Birth',
          children: birthDate
            ? dayjs(birthDate).format(DEFAULT_DATE_FORMAT.format)
            : ''
        },
        {
          key: `${idx}-7`,
          label: 'Address',
          children: getFormattedAddress(address)
        },
        ...(idx !== owners.length - 1
          ? [
              {
                key: `${idx}-8`,
                label: <Divider style={{ margin: 0 }} />,
                children: <Divider style={{ margin: 0 }} />
              }
            ]
          : [])
      )
    }
  )

  return (
    <Descriptions
      className='review-step__descriptions'
      title='Business Ownership Information'
      column={1}
      extra={
        <Button
          type='text'
          onClick={() => {
            dispatch(toggleIsNavigatedFromReview())
            dispatch(setDirection('backward'))

            setTimeout(() => {
              dispatch(
                changeStep({
                  currentStep:
                    BUSINESS_INFO_STEPS.at(2) ?? BUSINESS_INFO_STEPS[0]
                })
              )
            }, 100)
          }}
        >
          <img src={editIcon} alt='edit-icon' />
        </Button>
      }
      colon={false}
      items={items}
    />
  )
}

export default BusinessOwnersDescriptions
