import React, { useState } from 'react'

import { Button } from 'antd'

import BackgroundBlur from '@/components/icons/BackgroundBlur'
import { useTypedDispatch } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import {
  addHiddenStep,
  removeHiddenStep,
  setIsAgreedPrefill
} from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'

import PrivacyModal from '../owner-info/PrivacyModal'
import TermsModal from '../owner-info/TermsModal'

import './SaveTimeAi.scss'

// upload files and prefill data with Ai
const uploadWithAiSteps = [5, 6, 7, 8]
// default upload files
const uploadFilesSteps = [17, 18, 19]

const SaveTimeAi: React.FC<IStepProps> = ({ form, next }) => {
  const [modalToShow, setModalToShow] = useState<'terms' | 'policy' | ''>('')

  const dispatch = useTypedDispatch()

  const handleNext = (isSkipLoading: boolean) => {
    if (isSkipLoading) {
      dispatch(addHiddenStep(uploadWithAiSteps))
      dispatch(removeHiddenStep(uploadFilesSteps))
      dispatch(setIsAgreedPrefill(false))
      patch()
    } else {
      dispatch(addHiddenStep(uploadFilesSteps))
      dispatch(removeHiddenStep(uploadWithAiSteps))
      dispatch(setIsAgreedPrefill(true))
      patch()
    }
  }

  const [patch, isLoading] = usePatchApplication(next, async () => {
    return
  })

  const modalProps = {
    onCancel: () => setModalToShow(''),
    destroyOnClose: true,
    footer: null,
    centered: true
  } as const

  const buttonProps = {
    size: 'small',
    type: 'link',
    style: { padding: 0, textDecoration: 'none' }
  } as const

  return (
    <div className={'save-time-ai form-step'}>
      <div className={'save-time-ai__info'}>
        <h2 className={'save-time-ai__title'}>
          Save time with our <span>AI tool</span>: <br />
          Let us pre-fill your application!
        </h2>
        <p className={'save-time-ai__subtitle'}>
          Upload your most recent tax returns and driver’s license. Our AI tool
          will extract key details to complete your application, unlocking
          personalized loan options designed to fuel your business growth
        </p>
        <Button
          type={'primary'}
          onClick={() => handleNext(false)}
          className={'save-time-ai__next'}
          loading={isLoading}
        >
          Upload Returns
        </Button>
        <Button
          type={'default'}
          onClick={() => handleNext(true)}
          className={'save-time-ai__skip'}
          loading={isLoading}
        >
          Skip
        </Button>
        <BackgroundBlur className={'save-time-ai__info__background-blur'} />
      </div>
      <div className='save-time-ai__legal-docs-container'>
        <Button onClick={() => setModalToShow('terms')} {...buttonProps}>
          Terms of Service
        </Button>
        <Button onClick={() => setModalToShow('policy')} {...buttonProps}>
          Privacy Policy
        </Button>
        <TermsModal open={modalToShow === 'terms'} {...modalProps} />
      </div>

      <PrivacyModal open={modalToShow === 'policy'} {...modalProps} />
    </div>
  )
}

export default SaveTimeAi
