import { useEffect, useState } from 'react'

import { getFile } from '@/api/file.service'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import companyLogo from '@/images/loanbud-logo.svg'

export const useGetLogo = (logoId?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<string | null>(null)

  useEffect(() => {
    if (!logoId) {
      setData(companyLogo);
      return
    }

    const params = {
      catchCallback: () => setData(companyLogo),
      errorMessage: 'Unable to load logo'
    }

    const tryCallback = () => {
      setIsLoading(true)
      return getFile(logoId)
    }

    tryCatchWithMessage(tryCallback, params)
      .then((data) => {
        setData(data)

        const blob = new Blob([data], { type: 'image/png' })
        const url = URL.createObjectURL(blob)

        setData(url)
      })
      .finally(() => setIsLoading(false))
  }, [logoId])

  return { data, isLoading }
}
