import React, { ReactElement, useEffect, useState } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Spin } from 'antd'
import { FormInstance } from 'antd/lib'
import { Link } from 'react-router-dom'

import { partnersQueries } from '@/api/partners.service'
import { PartnerDto } from '@/generated-types'
import { useTypedSelector } from '@/hooks/redux'
import { useGetLogo } from '@/hooks/useGetLogo'
import useIsMobile from '@/hooks/useIsMobile'
import useShouldShowRejectedView from '@/hooks/useShouldShowRejectedView'
import backIcon from '@/images/icons/back-icon.svg'
import { timeLineSteps } from '@/utils/timeLineSteps'

import './TimeLine.scss'

type stepStatus = 'active' | 'not-active' | 'completed'

interface IStepStatuses {
  'basic-information': stepStatus
  'personal-information': stepStatus
  'business-information': stepStatus
  review: stepStatus
}

interface ITimeLineProps {
  prev: () => void
  form: FormInstance
}

const defaultCompanyUrl = 'https://loanbud.com/'

const TimeLine: React.FC<ITimeLineProps> = ({ prev, form }) => {
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData<Required<PartnerDto>>(
    partnersQueries.partner.me.queryKey
  )

  const { data: logo, isLoading: isLogoLoading } = useGetLogo(
    data?.logo?.id
  )

  const companyUrl = data?.url ?? defaultCompanyUrl

  const currentFormStep = useTypedSelector(
    ({ formState }) => formState.currentStep
  )

  const currentStep = useTypedSelector((state) => state.timeLine.currentStep)
  const [stepStatuses, setStepStatuses] = useState<IStepStatuses>({
    'basic-information': 'active',
    'personal-information': 'not-active',
    'business-information': 'not-active',
    review: 'not-active'
  })

  const isMobile = useIsMobile()

  useEffect(() => {
    switch (currentStep) {
      case 'personal-information':
        setStepStatuses({
          'basic-information': 'completed',
          'personal-information': 'active',
          'business-information': 'not-active',
          review: 'not-active'
        })
        break
      case 'business-information':
        setStepStatuses({
          'basic-information': 'completed',
          'personal-information': 'completed',
          'business-information': 'active',
          review: 'not-active'
        })
        break
      case 'review':
        setStepStatuses({
          'basic-information': 'completed',
          'personal-information': 'completed',
          'business-information': 'completed',
          review: 'active'
        })
        break
      default:
        setStepStatuses({
          'basic-information': 'active',
          'personal-information': 'not-active',
          'business-information': 'not-active',
          review: 'not-active'
        })
    }
  }, [currentStep])

  const shouldShowRejectedView = useShouldShowRejectedView(form)

  const shouldShowBackButton =
    ![0, 8].includes(currentFormStep) && !shouldShowRejectedView

  return (
    <>
      <section className={'time-line-mobile'}>
      {shouldShowBackButton && (
          <Button
            type='link'
            shape='circle'
            icon={<img src={backIcon} alt={'Back'} />}
            className={'time-line-mobile__back'}
            onClick={prev}
          >
            <span className='time-line-mobile__back-text'>Back</span>
          </Button>
        )}
        <div className={'time-line-mobile__steps'}>
          {timeLineSteps.map((step, index, array) => {
            const stepStatus: stepStatus = stepStatuses[step.key]
            let icon: ReactElement

            switch (stepStatus) {
              case 'active':
                icon = step.activeMobileIcon
                break
              case 'not-active':
                icon = step.notActiveMobileIcon
                break
              case 'completed':
                icon = step.completedMobileIcon
                break
              default:
                icon = step.activeMobileIcon
            }

            return (
              <div key={step.key} className={'time-line-mobile__item'}>
                {icon}
                {(stepStatus === 'active' || !isMobile) && step.mobileTitle}
                {index !== array.length - 1 && (
                  <div className={'time-line-mobile__divider'}></div>
                )}
              </div>
            )
          })}
        </div>
      </section>
      <section className={'time-line-desktop'}>
        <div className={'time-line-desktop__content'}>
          {shouldShowBackButton && (
            <div className={'time-line-desktop__back-btn-wrapper'}>
            <Button
              type='link'
              shape='circle'
              icon={<img src={backIcon} alt={'Back'} />}
              className={'time-line-desktop__back'}
              onClick={prev}
              ghost
            >
              Back
            </Button>
            </div>
          )}
          <Link to={companyUrl} className={'time-line-desktop__company-link'} target={'_blank'} rel='noreferrer'>
            {isLogoLoading || !logo ? (
              <Spin indicator={<LoadingOutlined spin style={{ fontSize: 38, color: 'var(--brand-primary)' }} />} />
            ) : (
              <img
                src={logo}
                alt={'company logo'}
                className={'time-line-desktop__logo'}
              />
            )}
          </Link>
          <div className={'time-line-desktop__steps'}>
            {timeLineSteps.map((step, index, array) => {
              const stepStatus: stepStatus = stepStatuses[step.key]
              let icon: ReactElement

              switch (stepStatus) {
                case 'active':
                  icon = step.activeDesktopIcon
                  break
                case 'not-active':
                  icon = step.notActiveDesktopIcon
                  break
                case 'completed':
                  icon = step.completedDesktopIcon
                  break
                default:
                  icon = step.activeDesktopIcon
              }

              return (
                <React.Fragment key={step.key}>
                  <div className={'time-line-desktop__item'}>
                    {icon}
                    <div className={'time-line-desktop__text'}>
                      <p
                        className={`time-line-desktop__title ${
                          ['active', 'completed'].includes(stepStatus) ? 'active' : ''
                        }`}
                      >
                        {step.desktopTitle}
                      </p>
                      <p className={'time-line-desktop__description'}>
                        {step.description}
                      </p>
                    </div>
                  </div>
                  {index !== array.length - 1 && (
                    <div
                      className={`time-line-desktop__divider ${
                        stepStatus === 'completed' ? 'green' : ''
                      }`}
                    ></div>
                  )}
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </section>
    </>
  )
}

export default TimeLine
