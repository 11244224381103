import { ReactElement } from 'react'

import CheckIcon from '@/components/icons/CheckIcon'

import './ThanksPage.scss'

const ThanksPage = (): ReactElement => {
  return (
    <div className={'thanks-page'}>
      <CheckIcon />
      <h2 className={'thanks-page__title'}>Thank you for applying.</h2>
      <p className={'thanks-page__subtitle'}>
        A member of our team will contact you shortly.
      </p>
    </div>
  )
}

export default ThanksPage
